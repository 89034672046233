.main-page-feature-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  /* background-image: url("/src/assets/images/main-background.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 100;
}

.main-page-feature-wrapper .options-div {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.revenue-iframe {
  position: absolute;
  border-radius: 4px;
  left: 10px;
  width: 125px;
  height: 60px;
  bottom: 10px;
  opacity: 0.5;
}

.top-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.logo-image {
  position: absolute;
  left: 49.5%;
  transform: translateX(-50%);
  top: -80px;
  width: 200px;
  height: 200px;
}

.city-dropdown {
  position: absolute;
  top: 70vh;
  left: 47%;
  transform: translate(-50%);
  width: 140px;
}

/* @media screen and (max-height: 601px) {
  .city-dropdown {
    top: 65vh;
  }
}
@media screen and (max-height: 541px) {
  .city-dropdown {
    top: 66vh;
  }
}
@media screen and (max-height: 353px) {
  .city-dropdown {
    top: 75vh;
  }
}
@media screen and (max-height: 229px) {
  .city-dropdown {
    top: 86vh;
  }
} */

.caresoul-wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-height: 830px) {
  .caresoul-wrapper {
    top: 29% !important;
  }
}

@media only screen and (max-height: 733px) {
  .caresoul-wrapper {
    top: 25% !important;
    width: 65vh !important;
  }
}
@media only screen and (max-height: 485px) {
  .caresoul-wrapper {
    top: 27% !important;
    width: 42vw !important;
    height: 55vh !important;
    left: 45%;
  }
}

@media only screen and (max-height: 328px) {
  .caresoul-wrapper {
    top: 20% !important;
    width: 65vh !important;
  }
}

.caresoul {
  width: 100%;
  height: 100%;
  z-index: 10;
}

.caresoul-images {
  /* max-width: 530px;
  max-height: 500px; */
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (min-width: 601px) {
  .caresoul-wrapper {
    height: 60vh;
    width: 52vw;
    left: 48%;
    top: 28vh;
  }
}

@media only screen and (min-width: 601px) {
}

.adds-div3 {
  position: absolute;
  left: 0%;
  bottom: 0%;
  /* transform: translateX(-50%); */
  z-index: 100;
}
.adds-div4 {
  display: none;
  position: absolute;
  right: 0%;
  bottom: 0%;
  /* transform: translateX(-50%); */
  z-index: 100;
}
.adds-div5 {
  position: absolute;
  right: 0%;
  bottom: 0%;
  /* transform: translateX(-50%); */
  z-index: 100;
}

.adds-div6 {
  position: absolute;
  right: 0%;
  top: 0%;
  /* transform: translateX(-50%); */
  z-index: 100;
}
@media only screen and (max-width: 450px) {
  .adds-div5 {
    display: none;
  }
  .adds-div3 {
    display: none;
  }

  .adds-div4 {
    display: block;
  }
  .adds-div6 {
    display: none;
  }
}

.revenue-component {
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 450px) {
  .caresoul-wrapper {
    width: 100vw !important;
  }

  .revenue-component {
    bottom: 60px !important;
    top: auto !important;
    left: 0 !important;
  }
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 10px;
}

