.admin-signin-root {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #24242c;
}

.admin-signin-root .container {
  width: 50%;
  height: 100%;
  background-color: #24242c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.admin-signin-root .pic {
  width: 50%;
  height: 100%;
  overflow: hidden;
  background-image: url("https://4kwallpapers.com/images/wallpapers/dark-blue-pink-2560x2560-12661.jpg");
  background-size: cover;
  background-position: center;
}

.admin-signin-root .inp {
  width: 350px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.admin-signin-root label {
  position: absolute;
  left: 20px;
  color: #777780;
  height: 20px;
  transform: translateY(2.5px);
  padding-left: 5px;
  cursor: text;
  padding-right: 5px;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
}

.admin-signin-root input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid #494954;
  border-radius: 10px;
  outline: none;
  transition: 0.4s;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}

.admin-signin-root input:focus {
  border: 2px solid #1f1fff;
  box-shadow: #6767ff 0px 1px 1px, #6767ff 0px 0px 0px 1px;
}

.admin-signin-root input:focus + label {
  left: 20px;
  transform: translateY(-22px);
  font-size: 12px;
  background-color: #24242c;
}

.admin-signin-root .up {
  left: 20px;
  transform: translateY(-22px);
  font-size: 12px;
  background-color: #24242c;
}

.admin-signin-root button {
  width: 350px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  background-color: #2020db;
  border: 2px solid #1f1fff;
  border-radius: 50px;
  outline: none;
  transition: 0.4s;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Figtree", sans-serif;
}

.admin-signin-root button:hover {
  background-color: #1717c2;
  border: 2px solid #1717c2;
}

h1 {
  font-family: "Be Vietnam Pro", sans-serif;
  font-family: "Figtree", sans-serif;
  color: #fff;
  margin-bottom: 20px;
}

.admin-signin-root a {
  color: #bbbbbb;
  text-decoration: none;
  font-size: 12px;
  font-family: "Figtree", sans-serif;
}

.admin-signin-root a:hover {
  text-decoration: underline;
}

.admin-signin-root img {
  width: 200px;

}

@media only screen and (max-width: 750px) {
  .admin-signin-root .pic {
    display: none;
  }

  /* .pic2 {
        display: block;
    } */

  .admin-signin-root .container {
    width: 100%;
  }
}

@media only screen and (max-height: 450px) {
  .admin-signin-root .pic {
    display: none;
  }

  .admin-signin-root .container {
    width: 100%;
  }

  .admin-signin-root {
    padding-bottom: 10px;
    overflow: scroll;
    height: 100%;
  }
}
