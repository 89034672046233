.search-position {
  position: absolute;
  top: 59vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 601px) {
  .search-position {
    top: 65vh;
  }
}
@media screen and (max-height: 541px) {
  .search-position {
    top: 66vh;
  }
}
@media screen and (max-height: 353px) {
  .search-position {
    top: 75vh;
  }
}
@media screen and (max-height: 229px) {
  .search-position {
    top: 86vh;
  }
}

.search-root {
  max-width: 600px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  gap: 7px;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 179px;
  align-items: center;
  padding: 0 5px 0 20px;
}

.search-root .search-icon {
  width: 15px;
  height: 15px;
}

.search-root .search-form{
  background: transparent;
  width: 100%;
  height: fit-content;
}

.search-root .search-input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  height: 50px;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}

.search-root .search-input::placeholder {
  color: white;
  font-family: "Inter";
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}

.search-root .search-engine-dropdown-option {
  display: inline-flex;
  gap: 20px;
  align-items: center;
}

.search-root .search-engine-dropdown-option img{
  width: 22px;
  height: 22px;
}

