.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap");
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter","Poppins", sans-serif;
}
