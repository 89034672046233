.news-container {
  max-width: 1300px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 60px;
  height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: 100px;
  right: 10px;
}

.card {
  background-color: transparent;
  position: relative;
  border-radius: 10px;
  padding-top: 200px;
}

.card img {
  width: 95%;
  height: 250px;
  object-fit: cover;
  position: absolute;
  top: -50px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.card-body .card-title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.card-body .card-text {
  font-size: 16px;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 30px;
  max-height: 5.4em; 
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.card-body .btn {
  width: 143px;
  height: 53px;
  display: block;
    align-self: center;
    border-radius: 10px;
  line-height: 50px;
  text-align: center;
  color: transparent;
  text-decoration: none;
  font-size: 20px;
  transition: 0.4s ease;
  border: 1px solid white;
  color: white;
}

.card-body .btn:hover {
  background-color: white;
  color: black;
}

@media screen and (max-width: 768px) {
  .news-container {
    grid-template-columns: 1fr;
    grid-row-gap: 100px;
  }
}

.dropdown-container .menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dropdown-container .menu-trigger {
  background: rgba(0, 0, 0, 0.4);
  width: 95px;
  height: 44px;
  border-radius: 40px;
  color: white;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.dropdown-container {
  z-index: 100;
}

.dropdown-container .menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.dropdown-container .menu-trigger span {

  vertical-align: middle;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0 10px;
}

.dropdown-container .menu-trigger img {
  border-radius: 90px;
}

.dropdown-container .menu {
    
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 356px;
  height: 711px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

@media (max-height: 799px) {
  .dropdown-container .menu {
    height: 500px;
  }
}

.dropdown-container .menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-container .menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-container .menu li {
  border-bottom: 1px solid #dddddd;
}

.dropdown-container .menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}
