@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
html,
body,
:root,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  
}
