.date-time-root{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: white;
    font-family: 'Inter';
    margin-left: 20px;
}

.date-time-root .time{
    font-weight: 800 ;
    font-size: 78px !important;
}

.date-time-root .date{
    font-weight: 700;
    font-size: 20px !important;
}