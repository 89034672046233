.generated-revenue-root {
  color: white;
  width: fit-content;
  height: fit-content;
  font-family: "Inter";
  font-size: 20px;
  color: #fff;

  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
