.upload-revenue-root {
  width: 100%;
  height: 100%;
  background-color: #24242c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-revenue-root .upload-revenue-container {
  width: 30vw;
  height: 40vh;
  background-color: #434344;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-revenue-root .current-revenue {
  width: 100%;
  height: fit-content;
  text-align: center;
  padding-top: 20px;
}

.upload-revenue-root .current-revenue h2 {
  color: white;
}

.upload-revenue-root .current-revenue p {
  color: white;
}

.upload-revenue-root .new-revenue {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.upload-revenue-root .new-revenue h2 {
  color: white;
}

.upload-revenue-root .inp {
  width: 350px;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.upload-revenue-root label {
  position: absolute;
  left: 20px;
  color: #777780;
  height: 20px;
  transform: translateY(2.5px);
  padding-left: 5px;
  cursor: text;
  padding-right: 5px;
  transition: 0.2s;
  font-family: Arial, Helvetica, sans-serif;
}

.upload-revenue-root input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 2px solid #494954;
  border-radius: 10px;
  outline: none;
  transition: 0.4s;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
}

.upload-revenue-root input:focus {
  border: 2px solid #1f1fff;
  box-shadow: #6767ff 0px 1px 1px, #6767ff 0px 0px 0px 1px;
}

.upload-revenue-root input:focus + label {
  left: 20px;
  transform: translateY(-22px);
  font-size: 12px;
  background-color: #434344;
}

.upload-revenue-root button {
  width: 350px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  background-color: #2020db;
  border: 2px solid #1f1fff;
  border-radius: 50px;
  outline: none;
  transition: 0.4s;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  cursor: pointer;
  font-family: "Figtree", sans-serif;
}

.upload-revenue-root .up {
  left: 20px;
  transform: translateY(-22px);
  font-size: 12px;
  background-color: #434344;
}

.upload-revenue-root button:hover {
  background-color: #1717c2;
  border: 2px solid #1717c2;
}
