.logout-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #ffffffeb; /* You can change the background color */
  color: black; /* You can change the text color */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect for the button */
.logout-button:hover {
  background-color: #767676; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
}

/* Optional: Additional styling for different states (active, focus) */
.logout-button:active,
.logout-button:focus {
  outline: none; /* Remove outline on click or focus */
  /* Add additional styles if needed */
}

.logout-option {
  padding: 0 20px;
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.logout-option img {
  width: 14px;
  height: 13.576px;
}

.logout-dropdown-container .menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logout-dropdown-container .menu-trigger {
  background: transparent;
  width: fit-content;
  height: fit-content;
  border-radius: 40px;
  color: white;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.logout-dropdown-container {
  z-index: 100;
}

.logout-dropdown-container .menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.logout-dropdown-container .menu-trigger span {
  vertical-align: middle;
  margin: 0 10px;
}

.logout-dropdown-container .menu-trigger span img {
  width: 18px;
  height: 23px;
}

.logout-dropdown-container .menu {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 202px;
  height: 64px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.logout-dropdown-container .menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.logout-dropdown-container .menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logout-dropdown-container .menu li {
  width: 202px;
  height: 64px;
  flex-shrink: 0;
}

.logout-dropdown-container .menu li a {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}
